import { captureException } from '@sentry/browser';
import geomagnetism from 'geomagnetism';

export const magneticDeclination = (
  latitude: number,
  longitude: number,
): number => {
  try {
    return geomagnetism.model().point([longitude, latitude]).decl;
  } catch (err) {
    captureException(err);
    return 20; // HACK
  }
};
