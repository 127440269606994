import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import type { MapboxGLButtonOptions } from './button-group.js';
import { MapboxGLButtonGroup } from './button-group.js';

interface Props {
  onStateChange: (state: 'visible' | 'half' | 'hidden') => void;
  state: 'visible' | 'half' | 'hidden';
}

export const OverlayControl: FC<Props> = ({ onStateChange, state }) => {
  const { current } = useMap();
  const control = useMemo(() => new MapboxGLButtonGroup(), []);

  useEffect(() => {
    current?.addControl(control);

    return () => {
      current?.removeControl(control);
    };
  }, [current, control]);

  useEffect(() => {
    const buttons: MapboxGLButtonOptions[] = [
      {
        content: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="28" height="28" fill="white"/>
          <rect x="8" y="6" width="12" height="17" stroke="black" stroke-width="2"/>
          <path d="M12 6C12.0273 6.63042 12.2761 6.9564 12.5 7.5C12.8086 8.22535 13.7281 8.92104 14 10C14.6015 12.3873 15.5 13 17 13C18 13 18.2567 12.864 19 13.5C19.3591 13.8073 19.6203 14.2563 20 14.5" stroke="black" stroke-width="1" stroke-linecap="round"/>
          <path d="M8 7.5C9.33188 8.99837 11.5 10.5 11.5 13C11 15 10.5 17.5 15.5 16C17 15.55 18.5 17 20 18.5" stroke="black" stroke-width="1" stroke-linecap="round"/>
          <path d="M17 7C17.0798 7.79775 18.2062 8.55386 18.8949 8.78342" stroke="black" stroke-width="1" stroke-linecap="round"/>
          <path d="M19 22C18 21 15.5 19.5 15 19.5C14.4846 19.5 11.7 20 10.5 20C9.3 20 8.33333 19.5 8 19.5" stroke="black" stroke-width="1"/>
        </svg>`,
        key: 'toggle-visibility',
        title: 'Toggle overlay visibility',
        eventHandler: () =>
          state !== 'hidden' ? onStateChange('hidden') : onStateChange('half'),
      },
    ];

    if (state !== 'hidden') {
      buttons.push({
        content: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="28" height="28" fill="white"/>
          <rect x="8" y="6" width="12" height="17" stroke="black" stroke-width="2"/>
          <path d="M12 6C12.0273 6.63042 12.2761 6.9564 12.5 7.5C12.8086 8.22535 13.7281 8.92104 14 10C14.6015 12.3873 15.5 13 17 13C18 13 18.2567 12.864 19 13.5C19.3591 13.8073 19.6203 14.2563 20 14.5" stroke="black" stroke-linecap="round"/>
          <path d="M8 7.5C9.33188 8.99837 11.5 10.5 11.5 13C11 15 10.5 17.5 15.5 16C17 15.55 18.5 17 20 18.5" stroke="black" stroke-linecap="round"/>
          <path d="M17 7C17.0798 7.79775 18.2062 8.55386 18.8949 8.78342" stroke="black" stroke-linecap="round"/>
          <path d="M19 22C18 21 15.5 19.5 15 19.5C14.4846 19.5 11.7 20 10.5 20C9.3 20 8.33333 19.5 8 19.5" stroke="black"/>
          <path d="M21 5L4.5 3V17L7 24L21 5Z" fill="#D9D9D9" style="mix-blend-mode:lighten"/>
        </svg>`,
        key: 'toggle-opacity',
        title: 'Toggle overlay opacity',
        eventHandler: () =>
          state === 'visible'
            ? onStateChange('half')
            : onStateChange('visible'),
      });
    }
    control.setButtons(buttons);
  }, [state, onStateChange, control]);
  return null;
};
