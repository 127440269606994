import turfDistance from '@turf/distance';
import type { LocationDataPoint } from '../contracts/data-point.js';

export const getDistance = (
  from: [number, number],
  to: [number, number],
): number => turfDistance(from, to, { units: 'meters' });

export const getLocationDistance = (
  from: LocationDataPoint,
  to: LocationDataPoint,
): number =>
  turfDistance([from.long, from.lat], [to.long, to.lat], { units: 'meters' });
