export interface DataPoint {
  when: Date;
  data?: Record<string, number | undefined>;
}

export interface LocationDataPoint extends DataPoint {
  lat: number;
  long: number;
}

export interface GeoJSONPoint {
  type: 'Point';
  coordinates: [number, number] | number[];
}

export interface GeoJSONDataPoint extends DataPoint {
  location: GeoJSONPoint;
}

export const isLocationDataPoint = (
  point: DataPoint,
): point is LocationDataPoint => {
  const location = point as LocationDataPoint;
  return location.lat !== undefined && location.long !== undefined;
};

export const toGeoJSONDataPoint = (
  point: LocationDataPoint,
): GeoJSONDataPoint => ({
  when: point.when,
  data: point.data ? { ...point.data } : undefined,
  location: { type: 'Point', coordinates: [point.long, point.lat] },
});
