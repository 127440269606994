export interface MapboxGLButtonOptions {
  className?: string;
  content: string;
  key: string;
  title: string;
  eventHandler: ((this: GlobalEventHandlers, ev: MouseEvent) => unknown) | null;
}

export class MapboxGLButtonGroup {
  private _container: HTMLElement;

  constructor() {
    const container = document.createElement('div');
    container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';

    this._container = container;
  }

  public onAdd(map: mapboxgl.Map): HTMLElement {
    return this._container;
  }

  public onRemove(map: mapboxgl.Map): void {
    this._container?.parentNode?.removeChild(this._container);
  }

  public setButtons(buttons: MapboxGLButtonOptions[]): void {
    while (this._container.firstChild) {
      this._container.removeChild(this._container.firstChild);
    }
    buttons.forEach(({ className, content, key, title, eventHandler }) => {
      const btn = document.createElement('button');
      btn.className = `mapboxgl-ctrl-icon ${className ?? ''}`;
      btn.innerHTML = content;
      btn.type = 'button';
      btn.title = title ?? '';
      btn.onclick = eventHandler;
      btn.id = key;
      this._container.appendChild(btn);
    });
  }
}
