import type {
  BBox,
  Feature,
  FeatureCollection,
  LineString,
  Point,
  Position,
} from '@turf/turf';
import { along, bbox, bboxPolygon, bearing, helpers } from '@turf/turf';
import type {
  Control,
  CourseControl,
  ResolvedControl,
} from '../contracts/control.js';
import type {
  DataPoint,
  GeoJSONPoint,
  LocationDataPoint,
} from '../contracts/data-point.js';
import { isLocationDataPoint } from '../contracts/data-point.js';
import { getDistance } from './distance.js';
import { isRogaine } from './event.js';

export type Bounds = [number, number, number, number];

export const bboxToBounds = (bbox: BBox): Bounds => [
  bbox[0],
  bbox[1],
  bbox[2],
  bbox[3],
];

export const resolveControls = (
  courseControls: CourseControl[] | undefined,
  eventControls: Record<string, Control> | undefined,
  eventType: string | null | undefined,
): ResolvedControl[] =>
  isRogaine(eventType)
    ? resolveRogaineControls(courseControls, eventControls)
    : resolveLinearControls(courseControls, eventControls);

export const resolveLinearControls = (
  courseControls: CourseControl[] | undefined,
  eventControls: Record<string, Control> | undefined,
): ResolvedControl[] => {
  if (!courseControls?.length) return [];

  return courseControls.map((courseControl) => {
    const controlCode = courseControl._id;
    const eventControl = eventControls?.[controlCode];
    return {
      ...eventControl,
      ...courseControl,
    };
  });
};

export const resolveRogaineControls = (
  courseControls: CourseControl[] | undefined,
  eventControls: Record<string, Control> | undefined,
): ResolvedControl[] => {
  if (courseControls?.length)
    return resolveLinearControls(courseControls, eventControls);

  const controls = eventControls ?? {};
  return Object.keys(controls).reduce<ResolvedControl[]>(
    (acc, key) => [...acc, { _id: key, ...controls[key] }],
    [] as ResolvedControl[],
  );
};

export const getEventScale = (eventType?: string | null): number =>
  eventType === 'sprint' ? 1 / 3 : 1;

const getIcon = (control: Control): string => {
  switch (control.type) {
    case 'start':
      return 'isom-701';
    case 'finish':
      return 'isom-706';
    default:
      return 'isom-703';
  }
};

export const controlHasLatLong = (
  control: ResolvedControl,
): control is ResolvedControl & { lat: number; long: number } =>
  control &&
  control.long !== null &&
  control.long !== undefined &&
  control.lat !== null &&
  control.lat !== undefined;

export const controlsToGeometry = (
  controls: ResolvedControl[],
  scale: number,
  highlightControls?: CourseControl[],
  showIndex = false,
): FeatureCollection<Point> => {
  const filtered = controls.filter(controlHasLatLong);

  return {
    type: 'FeatureCollection',
    features: filtered.map((control, index) => ({
      id: control._id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [control.long, control.lat],
      },
      properties: {
        id: !control.type ? (!showIndex ? control._id : index) : undefined,
        opacity:
          !highlightControls?.length ||
          highlightControls?.find((hl) => hl._id === control._id)
            ? 1
            : 0.25,
        rotation:
          index === 0 && filtered.length > 1
            ? bearing(
                [control.long, control.lat],
                [filtered[index + 1].long, filtered[index + 1].lat],
              )
            : 0,
        type: (control as Control).type ?? 'control',
        icon: getIcon(control as Control),
        'icon-casing': `${getIcon(control as Control)}-casing`,
        scale,
      },
    })),
  };
};

export const controlLinesToGeometry = (
  controls: ResolvedControl[] | undefined,
  scale: number,
  highlightControls?: CourseControl[],
): FeatureCollection<LineString> => {
  const filtered = controls?.filter(controlHasLatLong);

  if (!filtered?.length) return { type: 'FeatureCollection', features: [] };

  const { features } = filtered.slice(1).reduce<{
    prev: ResolvedControl & { lat: number; long: number };
    features: Feature<LineString>[];
  }>(
    (acc, control) => {
      const meters = getDistance(
        [acc.prev.long, acc.prev.lat],
        [control.long, control.lat],
      );
      if (meters < 50 * scale) return { ...acc, prev: control };

      const line = helpers.lineString([
        [acc.prev.long, acc.prev.lat],
        [control.long, control.lat],
      ]);
      const from = along(line, 25 * scale, { units: 'meters' });
      const to = along(line, meters - 25 * scale, { units: 'meters' });

      return {
        prev: control,
        features: [
          ...acc.features,
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [from.geometry.coordinates, to.geometry.coordinates],
            },
            properties: {
              meters,
              opacity:
                !highlightControls?.length ||
                (highlightControls.find((hl) => hl._id === acc.prev._id) &&
                  highlightControls.find((hl) => hl._id === control._id))
                  ? 1
                  : 0.1,
              scale,
            },
          },
        ],
      };
    },
    { prev: filtered[0], features: [] },
  );

  return {
    type: 'FeatureCollection',
    features,
  };
};

export const combineBbox = (bboxes: BBox[]): BBox => {
  const features = helpers.featureCollection(
    bboxes.map((bbox) => bboxPolygon(bbox)),
  );
  return bbox(features);
};

export const fromTurfPoint = (point: helpers.Point): GeoJSONPoint => ({
  type: 'Point',
  coordinates: [point.coordinates[0], point.coordinates[1]],
});

export const isLatLong = (value: unknown[]): value is [number, number] =>
  value.length === 2 &&
  typeof value[0] === 'number' &&
  typeof value[1] === 'number';

export const segmentsToLineString = (
  segments: DataPoint[][],
): helpers.FeatureCollection<helpers.LineString, helpers.Properties> =>
  helpers.lineStrings(
    segments.map((segment) =>
      segment
        .filter((point) => isLocationDataPoint(point))
        .map<Position>((point) => [
          (point as LocationDataPoint).long,
          (point as LocationDataPoint).lat,
        ]),
    ),
  );
