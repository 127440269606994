import type { PropertyValueSpecification } from '@maplibre/maplibre-gl-style-spec';
import type { Corners } from 'core';
import type { ComponentType } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useLocalStorage } from '../../../hooks/use-local-storage.js';
import { OverlayControl } from '../controls/overlay.js';

interface Props {
  beforeLayer?: string;
  corners: Corners | undefined;
  id?: string;
  imageUrl: string | undefined;
  interpolateOpacity: boolean;
}

interface ViewProps extends Props {
  onStateChange: (state: 'visible' | 'half' | 'hidden') => void;
  maxOpacity: number;
  minOpacity: number;
  state: 'visible' | 'half' | 'hidden';
}

const opacityInterpolation = (
  maxOpacity = 1,
  minOpacity = 0,
  scale = 1,
): PropertyValueSpecification<number> => [
  'interpolate',
  ['exponential', 2],
  ['zoom'],
  // min at zoom 18
  18 + Math.log(1 / scale) / Math.log(2),
  maxOpacity,
  // max at zoom 19
  19 + Math.log(1 / scale) / Math.log(2),
  minOpacity,
];

const ONE_PIXEL_GIF =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const withViewState =
  <P extends Props>(Component: ComponentType<ViewProps>): React.FC<P> =>
  (props) => {
    const [state, setState] = useLocalStorage<'visible' | 'half' | 'hidden'>(
      'overlay-visibility',
      'half',
    );
    return (
      <Component
        {...props}
        state={state}
        onStateChange={setState}
        maxOpacity={state === 'half' ? 0.5 : 1}
        minOpacity={state === 'half' ? 0 : 0.5}
      />
    );
  };

export const OverlayImageLayer = withViewState(
  ({
    beforeLayer,
    corners,
    id = 'default',
    imageUrl,
    interpolateOpacity,
    onStateChange,
    maxOpacity,
    minOpacity,
    state,
  }) => (
    <>
      <OverlayControl state={state} onStateChange={onStateChange} />
      <Source
        id={`map-overlay-${id}`}
        type="image"
        url={imageUrl ?? ONE_PIXEL_GIF}
        coordinates={
          corners ?? [
            [0, 0],
            [1, 0],
            [1, 1],
            [0, 1],
          ]
        }
      >
        <Layer
          id={`map-overlay-layer-${id}`}
          beforeId={beforeLayer}
          type="raster"
          source={`map-overlay-${id}`}
          paint={{
            'raster-opacity':
              state === 'hidden'
                ? 0
                : interpolateOpacity
                  ? opacityInterpolation(maxOpacity, minOpacity)
                  : maxOpacity,
          }}
        />
      </Source>
    </>
  ),
);
